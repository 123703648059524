<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-if="show"
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ modalData != null ? $t('ads.update_btn') : $t('ads.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="12">
          <text-input
              id="package-ad"
              v-model="form.name"
              :label="$t('ads.form.name')"
              :placeholder="$t('form.text.placeholder')"
              name="package-ad"
              rules="required"
          />
        </b-col>

        <b-col md="6">

          <wameed-image-cropper-input
              :label="$t('ads.form.image')"
              :placeholder="$t('form.image.placeholder')"
              :uploaded-image="profileImage"
              :get-object="true"
              :fixed="true"
              @changed="uploadProfileImage"
              :width="323"
              :height="180"
          />
          <!--          <wameed-input-file-upload-->
          <!--              fileType="images"-->
          <!--              field-classes="w-100"-->
          <!--              rule="required"-->
          <!--              functionType="front"-->
          <!--              v-model="form.image.name"-->
          <!--              :label="$t('ads.form.image')"-->
          <!--              :input-placeholder="$t('form.image.placeholder')"-->
          <!--              :input-append-text="$t('form.image.browse')"-->
          <!--              @changed="uploadProfileImage"-->
          <!--          />-->
        </b-col>
        <b-col md="6">
          <wameed-image-cropper-input
              :label="$t('ads.form.image_web')"
              :placeholder="$t('form.image.placeholder')"
              :uploaded-image="webImage"
              :get-object="true"
              :fixed="true"
              @changed="uploadWebImage"
              :width="323"
              :height="180"
          />
          <!--          <wameed-input-file-upload-->
          <!--              fileType="images"-->
          <!--              field-classes="w-100"-->
          <!--              rule="required"-->
          <!--              functionType="front"-->
          <!--              v-model="form.image_web.name"-->
          <!--              :label="$t('ads.form.image_web')"-->
          <!--              :input-placeholder="$t('form.image.placeholder')"-->
          <!--              :input-append-text="$t('form.image.browse')"-->
          <!--              @changed="uploadWebImage"-->
          <!--          />-->
        </b-col>

        <b-col md="6">
          <wameed-dropdown
              v-model="form.category"
              :label="$t('ads.form.category')"
              :placeholder="$t('form.text.placeholder')"
              variant="disable"
              :items="getVendorCategories"
              title="name"
              class="wameed_dropdown"

              :no_options="$t('common.no_options')"
              @input="onTypeChange"
          />
        </b-col>
        <b-col md="6">
          <wameed-dropdown
              :searchable="true"
              v-model="form.vendor"
              :label="$t('ads.form.vendor')"
              :placeholder="$t('form.text.placeholder')"
              variant="disable"
              :items="getVendorsOnCategory"
              title="name"
              class="wameed_dropdown"

              :no_options="$t('common.no_options')"
          />
        </b-col>
      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions, mapGetters} from 'vuex';
import WameedImageCropperInput from "@/components/wameed/WameedImageCropperInput.vue";


export default {
  components: {
    WameedImageCropperInput,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    modalData: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {

      form: {
        name: '',
        image: '',
        image_web: '',
        category: '',
        vendor: '',
      },
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getVendorCategories: 'utilities/getVendorCategories',
      getVendorsOnCategory: 'utilities/getVendorsOnCategory',
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    profileImage() {
      let image = "";
      if(this.form.image)
      if ("name" in this.form.image) {
        image = this.form.image.name;
      } else {
        image = this.form.image;
      }
      return this.form.image;
    },

    webImage() {
      let image = "";
      if(this.form.image_web)
      if ("name" in this.form.image_web) {
        image = this.form.image_web.name;
      } else {
        image = this.form.image_web;
      }
      return this.form.image_web;
    },

  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
      } else {
        this.form = {
          name: '',
          image: '',
          image_web: '',
          category: '',
          vendor: '',
        };
      }
    },
    show(val) {
      if (this.modalData && this.modalData.image) {
        this.form.image = this.modalData.image;
      }
      if (!val) {
        this.$emit('close');
      }
    },
  },
  created() {
    if (this.modalData != null) {
      this.form = this.modalData
    }
  },
  methods: {
    ...mapActions({
      create: "admin/ads/create",
      update: "admin/ads/update",
    }),
    uploadProfileImage(value) {
      this.form.image = value;
    },
    uploadWebImage(value) {
      this.form.image_web = value;
    },

    onTypeChange(type) {
      this.form.vendor = '';
      this.$store.dispatch('utilities/getVendorsOnCategory', type.id)
    },
    submitOrder() {

      this.form.category_id = this.form.category.id;
      this.form.vendor_id = this.form.vendor.id;
      if (this.modalData == null) {
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      } else {
        this.update({
          ...this.form,
          id: this.modalData.id,

        }).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, {root: true});
        });
      }
    },


    closeModal() {
      this.form = {
        name: '',
        image: '',
        image_web: '',
        category: '',
        vendor: '',
      };
      this.show = false;
    },
  },
};
</script>
